<template>
  <div :class="$style.username" :style="{ color: textColor }">
    <span :style="style"><slot /></span>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { createTextColorStyle } from '~/utils/color'
import type { Color } from './types'

defineOptions({
  name: 'UiUsername'
})

const props = withDefaults(
  defineProps<{
    color?: Color
  }>(),
  {
    color: null
  }
)

const style = computed(() =>
  props.color ? createTextColorStyle(props.color) : ''
)

const textColor = computed(() =>
  Array.isArray(props.color)
    ? (props.color[props.color.length - 1] as string)
    : props.color ?? undefined
)
</script>

<style module lang="scss">
.username {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-background-clip: text !important;
}
</style>
